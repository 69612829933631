var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-app',{attrs:{"id":"app"}},[_c('v-snackbar',{attrs:{"color":"#FF1313","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-divider'),_c('Default'),_c('router-view',{key:_vm.$route.fullPath,staticStyle:{"min-height":"90vh"},style:(_vm.$vuetify.breakpoint.name == 'md'
          ? 'padding-top:50px'
          : _vm.$vuetify.breakpoint.name == 'xs'
          ? 'padding-top:99px'
          : 
          _vm.$vuetify.breakpoint.name == 'sm'
          ? 'padding-top:69px'
          :''),attrs:{"storage":_vm.sideNav}}),_c('AppFooter')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }