<template>
  <div>
    
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-app id="app">
      <v-snackbar
        v-model="showSnackBar"
        color="#FF1313"
        right
        :timeout="timeout"
      >
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-divider></v-divider>
      <!-- <AppHeader0 /> -->
      <!-- <AppHeader /> -->
      <Default />

      
      <router-view
        :style="
          $vuetify.breakpoint.name == 'md'
            ? 'padding-top:50px'
            : $vuetify.breakpoint.name == 'xs'
            ? 'padding-top:99px'
            : 
            $vuetify.breakpoint.name == 'sm'
            ? 'padding-top:69px'
            :''
        "
        style="min-height: 90vh"
        :key="$route.fullPath"
        v-bind:storage="sideNav"
      />
      <AppFooter />
    </v-app>
    <!-- <AppFooter v-if="$route.name != 'Login'" v-bind:storage="styloopData" /> -->
  </div>
</template>
<script>
// import AppHeader0 from "./components/Common/appHeader0";
// import AppHeader from "./components/Common/appHeader";
// import Default from "./components/Common/Default";
// import AppHeader2 from "./components/Common/appHeader2";
// import AppHeader3 from "./components/Common/appHeader3";
import AppFooter from "./components/Common/appFooter";
// import axios from "axios";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";

export default {
  components: {
    // AppHeader0,
    // AppHeader,
    // Default,
    // AppHeader2,
    // AppHeader3,
    AppFooter,
  },
  data() {
    return {
      sideNav: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      styloopData: {},
      queryPopUpDialog: false,
    };
  },

  beforeMount() {},
  methods: {},
};
</script>
